/*
 * When clicking on certain elements we can add or remove
 * body classes in order to show or hide elements
 * accordingly
 */
var addRemoveClasses = (function() {

	var init = function() {
		$(document).on('click', '[data-toggle-class]', function(e) {
			e.preventDefault();
			var $class = $(this).data('toggle-class');
			var $target = typeof $(this).data('toggle-on') !== 'undefined' ? $($(this).data('toggle-on')) : $('body');
			$target.toggleClass($class);
		});
		$(document).on('click', '[data-add-class]', function(e) {
			e.preventDefault();
			var $class = $(this).data('add-class');
			var $target = typeof $(this).data('add-to') !== 'undefined' ? $($(this).data('add-to')) : $('body');
			if( ! $target.hasClass($class) ) {
				$target.addClass($class);
			}
		});
		$(document).on('click', '[data-remove-class]', function(e) {
			e.preventDefault();
			var $class = $(this).data('remove-class');
			var $target = typeof $(this).data('remove-from') !== 'undefined' ? $($(this).data('remove-from')) : $('body');
			if( $target.hasClass($class) ) {
				$target.removeClass($class);
			}
		});
	};

	return {init: init};

})();

addRemoveClasses.init();

$(document).on('submit', '[data-confirm]', function(e) {
	e.preventDefault();
	if( ! confirm($(this).data('confirm')) ) return false;
});

if ( $.isFunction($.fn.summernote) ) {
	$('[data-wysiwyg]').summernote({
		height: 200,
		toolbar: [
			['style', ['bold', 'italic', 'underline']],
			['insert', ['link']],
		]
	});
}